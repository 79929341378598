import React from "react";
import CustomChip from "../../../common/CustomChip/CustomChip";

const chipStrings = {
  "just-to-start": "Just to Start",
  "*|REVIEW_LINK|*": "Review Link",
  "*|HOMEOWNER_FIRST_NAME|*": "Homeowner's First Name",
  "*|ANOTHER_WORD|*": "My Number (Call)",
};

const RequestAReviewModalCustomTextArea = () => {
  return <CustomChip CustomChipTextArea={chipStrings} />;
};

export default RequestAReviewModalCustomTextArea;
