import { useRouter } from "next/router";
import React, {
  Dispatch,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { toast } from "react-toastify";
import { useSWRConfig } from "swr";
import validator from "validator";
import { DesktopContext } from "../../../../contexts/DesktopContextProvider/DesktopContextProvider";
import { useNewPostModal } from "../../../../hooks/useNewPostModal/useNewPostModal";
import ActionModal from "../../../common/ActionModal/ActionModal";
import UtilityModal from "../../../common/UtilityModal/UtilityModal";
import EmptyState from "../../../uiwrappers/EmptyState/EmptyState";
import { IContact } from "../../contacts/Contact/types";
import {
  validateEmail,
  validatePhoneNumber,
} from "../../contacts/helpers/formatPhoneNumber";
import AddRecipientModal from "../AddRecipientModal/AddRecipientModal";
import RequestAReviewModalCustomButtons from "../RequestAReviewModalCustomButtons/RequestAReviewModalCustomButtons";
import RequestAReviewModalCustomTextArea from "../RequestAReviewModalCustomTextArea/RequestAReviewModalCustomTextArea";
import RequestAReviewModalStickyTop from "../RequestAReviewModalStickyTop/RequestAReviewModalStickyTop";
import RequestReviewMessageWithVariables from "../RequestReviewMessageWithVariables/RequestReviewMessageWithVariables";
import ReviewContactTable from "../ReviewContactTable/ReviewContactTable";
import { postReviewRequest } from "../ReviewContainer/utils";
import { SEND_CHANNEL_TYPE } from "./constants";
import { ChannelType, ContactsVisibility } from "./types";
import {
  adjustMessageForMultipleNames,
  categorizeContacts,
  channelTypeConfig,
  checkIfAlreadySentRequestReviewWithin6Months,
  determineInformativeMessage,
  determineSubAlert,
  filterAndSortContacts,
  getCounterAndInvalidRecipients,
  getDefaultRequestReviewForLandingPage,
  isContactMatchingSearch,
  isContactNotInRecipients,
  parseRecipients,
  replaceMessageWithSingleName,
  updateContactsForReviewSent,
} from "./utils";
import { BLANK_CONTACT } from "../../contacts/ContactsPage/constants";
import useContactInfo from "../../../../hooks/useContactInfo/useContactInfo";

interface RequestAReviewModalProps {
  setReviewRequestModalOpen: any;
  reviewRequestModalOpen: any;
  recipients: IContact[];
  setRecipients: Dispatch<SetStateAction<IContact[]>>;
  fromContactsPage?: boolean;
  setSharingReviewLink?: (arg0: boolean) => void;
  postSaveAction?: () => void;
}

export const RequestAReviewModalContext = createContext({} as any);

export default function RequestAReviewModal({
  setReviewRequestModalOpen,
  reviewRequestModalOpen,
  recipients,
  setRecipients,
  fromContactsPage,
  setSharingReviewLink,
  postSaveAction,
}: RequestAReviewModalProps) {
  const {
    profileInfo,
    contacts,
    requestsCount,
    setRequestCount,
    gmbVerificationStatus,
    setContacts,
    basicInfo,
  } = useContext(DesktopContext);

  const { mutateContactInfo } = useContactInfo(profileInfo);
  const { slug } = profileInfo || {};
  const { default_request_review_text } = basicInfo || "";
  const { default_review_link } = basicInfo || "";
  const [stage, setStage] = useState("1");
  const [showMessage, setShowMessage] = useState(false);
  const [informativeMessage, setInformativeMessage] = useState("");

  const [counter, setCounter] = useState(0);
  const [invalidRecipients, setInvalidRecipients] = useState<number[]>([]);
  const [alert, setAlert] = useState("");
  const [searchedContact, setSearchedContact] = useState("");
  const [editedRecipient, setEditedRecipient] = useState("");

  const [areValidRecipients, setAreValidRecipients] = useState(true);
  const [displayedContactsInModal, setDisplayedContactsInModal] = useState<
    IContact[]
  >([]);
  const [editRecipientModalOpen, setEditRecipientModalOpen] = useState(false);
  const [localRequestReviewMessage, setLocalRequestReviewMessage] =
    useState("");
  const [isAddingRecipient, setIsAddingRecipient] = useState(false);
  const [newContact, setNewContact] = useState("");
  const [addRecipientModalOpen, setAddRecipientModalOpen] =
    useState(isAddingRecipient);
  const [createPostModalOpen, setCreatePostModalOpen] = useState(false);
  const [currentSelection, setCurrentSelection] = useState("neutral");

  const [subAlert, setSubAlert] = useState("");

  const [defaultMessage, setDefaultMessage] = useState("");
  const [stageWhenFocused, setStageWhenFocused] = useState("1");
  const { mutate } = useSWRConfig();

  const [isSubmitProcessing, setIsSubmitProcessing] = useState(false);
  const [isSecondSubmitProcessing, setIsSecondSubmitProcessing] =
    useState(false);
  const [isThirdSubmitProcessing, setIsThirdSubmitProcessing] = useState(false);

  const [connectToFBModalOpen, setConnectToFBModalOpen] = useState(false);
  const [contactsVisibility, setContactsVisibility] =
    useState<ContactsVisibility>(ContactsVisibility.NONE);

  const [isAddAllClicked, setIsAddAllClicked] = useState(false);

  const router = useRouter();

  useEffect(() => {
    if (router.query.reviewRecipient) {
      const id = router.query.reviewRecipient;
      if (id) {
        const contactId = parseInt(id as string);
        const contact = contacts.find((c) => c.id == contactId);
        if (contact) {
          contactOnClickHandler(contact);
          setStage("2");
        }
      }
    }
  }, []);

  useEffect(() => {
    const results = getCounterAndInvalidRecipients(contacts, 0);
    setInvalidRecipients(results.invalidRecipientsVar);
    setCounter(results.newCounter);
  }, [recipients]);

  useEffect(() => {
    const mobileNavbar = document.getElementById("mobile-head-navbar");
    if (mobileNavbar) {
      if (reviewRequestModalOpen) {
        mobileNavbar.classList.add("hidden");
      } else {
        mobileNavbar.classList.remove("hidden");
      }
    }
  }, [reviewRequestModalOpen]);

  useEffect(() => {
    if (fromContactsPage || recipients.length > 0) {
      setStage("2");
    }
  }, [reviewRequestModalOpen]);

  useEffect(() => {
    const localStorageMessage = localStorage.getItem("message");
    const localStorageShowAll = localStorage.getItem("showAll");
    if (localStorageMessage && reviewRequestModalOpen) {
      setDefaultMessage(localStorageMessage);
      localStorageShowAll &&
        setContactsVisibility(localStorageShowAll as ContactsVisibility);
      setLocalRequestReviewMessage(localStorageMessage);
      localStorage.removeItem("message");
      localStorage.removeItem("showAll");
    } else {
      const firstName = profileInfo?.first_name;
      const lastName = profileInfo?.last_name;
      const companyName = profileInfo?.company_name;
      const phone = basicInfo?.phone;
      const email = basicInfo?.email;

      const defaultRequestReviewForLandingPage =
        getDefaultRequestReviewForLandingPage(
          default_request_review_text,
          firstName,
          lastName,
          companyName,
          phone,
          email
        );
      const trimmedDefaultMessage = defaultRequestReviewForLandingPage?.trim();
      setDefaultMessage(trimmedDefaultMessage);
      setLocalRequestReviewMessage(trimmedDefaultMessage);
    }
  }, [
    profileInfo,
    basicInfo,
    reviewRequestModalOpen,
    default_request_review_text,
  ]);

  useEffect(() => {
    if (recipients.length === 0) {
      setCurrentSelection("neutral");
      if (defaultMessage) {
        setLocalRequestReviewMessage(defaultMessage);
      }
    }
  }, [recipients]);

  useEffect(() => {
    if (!!contacts && contacts.length > 0) {
      const newContacts = filterAndSortContacts(
        contacts,
        invalidRecipients,
        recipients
      );
      setDisplayedContactsInModal(newContacts);
    }
  }, [contacts, recipients, invalidRecipients]);

  // to DO - Confirm if we want this and the other to do useeffects

  useEffect(() => {
    if (
      !editRecipientModalOpen &&
      !addRecipientModalOpen &&
      recipients.length > 0
    ) {
      setStage("2");
    }
  }, [editRecipientModalOpen, addRecipientModalOpen]);

  useEffect(() => {
    if (alert === "") {
      setSubAlert("");
    }
  }, [alert]);

  useEffect(() => {
    const subAlert = determineSubAlert(counter);
    if (subAlert) {
      setSubAlert(subAlert);
    }

    setInformativeMessage(determineInformativeMessage(counter));
  }, [counter]);

  useEffect(() => {
    const allRecipientsHaveBothPhoneAndEmail = recipients.every(
      (recipient: any) => !!recipient.phone_number && !!recipient.email
    );

    setCurrentSelection(
      allRecipientsHaveBothPhoneAndEmail ? "neutral" : "send"
    );
  }, [recipients]);

  useEffect(() => {
    if (recipients.length === 0) return;

    const firstName = recipients[0]?.first_name;

    if (!firstName) return;

    let updatedMessage;

    if (recipients.length === 1) {
      updatedMessage = replaceMessageWithSingleName(
        localRequestReviewMessage,
        firstName
      );
    } else if (recipients.length > 1) {
      updatedMessage = adjustMessageForMultipleNames(
        localRequestReviewMessage,
        firstName
      );
    }

    // Update message if needed
    if (updatedMessage) {
      setLocalRequestReviewMessage(updatedMessage);
    }
  }, [recipients]);

  useEffect(() => {
    const recipientsElement = document.getElementById("recipients");
    if (recipientsElement) {
      const height = recipientsElement.offsetHeight;
      if (height > 80) {
        setContactsVisibility((prevState) =>
          prevState === ContactsVisibility.HIDE
            ? ContactsVisibility.HIDE
            : ContactsVisibility.SHOW_ALL
        );
      } else {
        setContactsVisibility(ContactsVisibility.NONE);
      }
    }
  }, [recipients]);

  useEffect(() => {
    if (!createPostModalOpen) {
      setSharingReviewLink && setSharingReviewLink(false);
    }
  }, [createPostModalOpen]);

  function handleFocus() {
    setStage("1");
    setStageWhenFocused(stage);
  }

  function handleBlur(e: any) {
    if (!e.relatedTarget && stageWhenFocused === "2") {
      setStage(searchedContact === "" ? "2" : "1");
    }
  }

  function onChangeHandler(e: any) {
    const contactInput = e["contact-text"];
    setNewContact(contactInput);
    setSearchedContact(contactInput);
    setAlert("");

    const searchTerm = contactInput.toLowerCase();
    const filteredContacts = contacts.filter(
      (contact) =>
        isContactNotInRecipients(contact, recipients) &&
        (searchTerm.length === 0 ||
          isContactMatchingSearch(contact, searchTerm))
    );

    setDisplayedContactsInModal(filteredContacts);
  }

  function addNewContactHandler() {
    setReviewRequestModalOpen(false);
    setIsAddingRecipient(true);
    setAddRecipientModalOpen(true);
    localStorage.setItem("showAll", contactsVisibility);
    localStorage.setItem("message", localRequestReviewMessage);
  }

  function contactOnClickHandler(contact: any) {
    setAlert("");
    if (typeof contact === "string") {
      contact = contact.trim();
      if (
        !validator.isMobilePhone(contact, "en-US") &&
        !validator.isEmail(contact)
      ) {
        setAlert("Please enter a valid email or phone number");
        return;
      } else if (validateEmail(contact)) {
        setCurrentSelection("email");

        // key value is added to help easily filter recipient in deleteRecipientHandler function in Recipient.tsx

        setRecipients([
          ...recipients,
          {
            ...BLANK_CONTACT,
            email: contact,
            value: contact,
          } as any,
        ]);
      } else if (validatePhoneNumber(contact)) {
        setCurrentSelection("text");
        setRecipients([
          ...recipients,
          {
            ...BLANK_CONTACT,
            phone_number: contact,
            value: contact,
          } as any,
        ]);
      }
    }
    // if there is id in contact, append contact to recipients array
    else {
      if (contact.date_request_review_sent) {
        const bool = checkIfAlreadySentRequestReviewWithin6Months(
          new Date(contact.date_request_review_sent)
        );
        if (bool) {
          setCounter(counter + 1);
        }
      } else {
        setRecipients([...recipients, contact]);
      }
    }

    const newDisplayedContacts = displayedContactsInModal.filter(
      (c) => c.id !== contact.id
    );
    setDisplayedContactsInModal(newDisplayedContacts);
    setSearchedContact("");
    setStage("2");
  }

  function onCancelHandler() {
    setReviewRequestModalOpen(false);
    setShowMessage(false);

    setCounter(0);
    setInvalidRecipients([]);
    setAlert("");
    setInformativeMessage("");
    setStage("1");

    setTimeout(() => {
      setSearchedContact("");
      setRecipients([]);
    }, 500);
  }

  function validateRecipientInfo(channelType: ChannelType): boolean {
    if (recipients.length === 0) {
      setAreValidRecipients(true);
      return true;
    }
    const config = channelTypeConfig[channelType];
    const valid = recipients.every(config.validator);

    setAreValidRecipients(valid);

    if (!valid && config.alertMessage) {
      setAlert(config.alertMessage);
    }

    return valid;
  }

  async function sendReviewRequest(
    channelType: string,
    contactIds: number[],
    nonContactRecipients: IContact[]
  ): Promise<void> {
    await postReviewRequest(
      slug,
      channelType,
      contactIds,
      nonContactRecipients,
      localRequestReviewMessage,
      false
    );
    mutate([`/api/review-requests?slug=`, profileInfo?.slug]);
    mutateContactInfo();
    toast.success("Review request sent");
  }

  function resetStateAfterSave(): void {
    setReviewRequestModalOpen(false);
    setShowMessage(false);
    setAlert("");

    setTimeout(() => {
      setSearchedContact("");
      setRecipients([]);
    }, 500);

    setRequestCount(requestsCount + recipients.length);
  }

  async function onSaveHandler(channelType: ChannelType): Promise<void> {
    const isValidRecipient = validateRecipientInfo(channelType);

    if (alert || !isValidRecipient || recipients.length === 0) {
      setAlert("Required");
      return;
    }

    if (areValidRecipients || channelType === SEND_CHANNEL_TYPE) {
      const { contactIds, nonContactRecipients } = parseRecipients(recipients);
      const newContacts = updateContactsForReviewSent(contacts, recipients);
      setContacts(newContacts);
      await sendReviewRequest(channelType, contactIds, nonContactRecipients);
      resetStateAfterSave();
    }
  }
  function handleClickShowAll() {
    const recipientsElement = document.getElementById("recipients");

    if (contactsVisibility === ContactsVisibility.SHOW_ALL) {
      setContactsVisibility(ContactsVisibility.HIDE);
    } else {
      if (recipientsElement) {
        recipientsElement.scrollIntoView({ behavior: "auto" });
      }
      setContactsVisibility(ContactsVisibility.SHOW_ALL);
    }
  }

  async function onSubmitHandler() {
    setIsSubmitProcessing(true);
    setCurrentSelection("text");
    await onSaveHandler("text");
    await postSaveAction?.();
    setIsSubmitProcessing(false);
  }

  async function onSecondSubmitHandler() {
    setIsSecondSubmitProcessing(true);
    setCurrentSelection("email");
    await onSaveHandler("email");
    await postSaveAction?.();
    setIsSecondSubmitProcessing(false);
  }

  async function onThirdSubmitHandler() {
    setIsThirdSubmitProcessing(true);
    await onSaveHandler("send");
    await postSaveAction?.();
    setIsThirdSubmitProcessing(false);
  }

  function handleClose() {
    if (reviewRequestModalOpen) {
      setReviewRequestModalOpen(false);

      setSearchedContact("");
      setRecipients([]);
      setContactsVisibility(ContactsVisibility.NONE);
      setCounter(0);
      setInvalidRecipients([]);
      setAlert("");
      setInformativeMessage("");
      setStage("1");
    } else {
      setReviewRequestModalOpen(true);
    }
  }

  const handleLocalMessageOnChange = (e: any) => {
    setLocalRequestReviewMessage(e.target.value);
  };

  const { renderCreateAPostModal } = useNewPostModal();

  function handleAddAllRecipients(): void {
    setStage("2");
    const { validContacts, invalidContactsCount, invalidContactIDs } =
      categorizeContacts(contacts);

    setCounter(invalidContactsCount);
    setInvalidRecipients(invalidContactIDs);
    setRecipients(validContacts);
    setIsAddAllClicked(true);
  }

  const RequestAReviewModalContextValue = {
    recipients,
    setRecipients,
    isSecondSubmitProcessing,
    onSecondSubmitHandler,
    isSubmitProcessing,
    onSubmitHandler,
    currentSelection,
    isThirdSubmitProcessing,
    onThirdSubmitHandler,
    setReviewRequestModalOpen,
    setConnectToFBModalOpen,
    setCreatePostModalOpen,
    setSharingReviewLink,
    addNewContactHandler,
    onChangeHandler,
    searchedContact,
    handleFocus,
    handleBlur,
    contactsVisibility,
    informativeMessage,
    alert,
    setAlert,
    editRecipientModalOpen,
    stage,
    setStage,
    counter,
    setEditRecipientModalOpen,
    setEditedRecipient,
    onCancelHandler,
    areValidRecipients,
    setAreValidRecipients,
    setDisplayedContactsInModal,
    displayedContactsInModal,
    subAlert,
    handleAddAllRecipients,
    localRequestReviewMessage,
    handleClickShowAll,
    handleLocalMessageOnChange,
    isAddAllClicked,
    setIsAddAllClicked,
  };

  const showEmptyState =
    contacts?.length === 0 && recipients.length === 0 && searchedContact === "";
  const isStageOne = stage === "1";
  const isStageTwo = stage === "2";

  return (
    <RequestAReviewModalContext.Provider
      value={RequestAReviewModalContextValue}
    >
      <UtilityModal
        isOpen={reviewRequestModalOpen}
        setIsOpen={onCancelHandler}
        onCancelHandler={handleClose}
        title="Request a Review"
        showCancelButton={false}
        showSubmitButton={false}
        onSubmitHandler={onSubmitHandler}
        footerBorder={true}
        customButtons={<RequestAReviewModalCustomButtons />}
        elementStickyToTop={<RequestAReviewModalStickyTop />}
        width="sm:w-800"
        height="sm:h-auto sm:min-h-500"
        customOverflow="overflow-y-auto"
      >
        <div id="request-review-section" className="w-full h-full ">
          {showEmptyState && (
            <EmptyState
              header="No contacts"
              subheader="Get started by creating your first contact OR enter a phone number or email above"
              icon="contacts"
            />
          )}
          {isStageOne && (
            <ReviewContactTable
              displayedContacts={displayedContactsInModal}
              contactOnClickHandler={contactOnClickHandler}
              newContact={newContact}
              searchedContact={searchedContact}
              handleAddAllRecipients={handleAddAllRecipients}
            />
          )}
          {showMessage && <RequestReviewMessageWithVariables />}
          {isStageTwo && <RequestAReviewModalCustomTextArea />}
        </div>
      </UtilityModal>

      {renderCreateAPostModal(createPostModalOpen, setCreatePostModalOpen)}

      {!createPostModalOpen && connectToFBModalOpen && (
        <ActionModal
          open={connectToFBModalOpen}
          setOpen={setConnectToFBModalOpen}
          header="You're not connected"
          subheader={"In order to use this feature, please connect to Facebook"}
          primaryButtonText={"Connect"}
          facebook={true}
          icon="Delete"
          hideCancel
        />
      )}

      {addRecipientModalOpen && (
        <AddRecipientModal
          recipients={recipients}
          addRecipientModalOpen={addRecipientModalOpen}
          setAddRecipientModalOpen={setAddRecipientModalOpen}
          setIsAddingRecipient={setIsAddingRecipient}
          setRecipients={setRecipients}
          setReviewRequestModalOpen={setReviewRequestModalOpen}
        />
      )}
      {editRecipientModalOpen && (
        <AddRecipientModal
          editRecipientModalOpen={editRecipientModalOpen}
          setEditRecipientModalOpen={setEditRecipientModalOpen}
          recipients={recipients}
          setRecipients={setRecipients}
          editedRecipient={editedRecipient}
          setReviewRequestModalOpen={setReviewRequestModalOpen}
        />
      )}
    </RequestAReviewModalContext.Provider>
  );
}
