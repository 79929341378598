import React, { useContext, useEffect, useState } from "react";
import { DesktopContext } from "../../../../contexts/DesktopContextProvider/DesktopContextProvider";
import { getReviewRequestBodyText } from "../ReviewContainer/utils";
import DynamicInput from "../../../uiwrappers/GenericDynamicInput/GenericDynamicInput";
import { getReviewLink } from "../RequestAReviewModal/utils";

export default function RequestReviewMessageWithVariables() {
  const { profileInfo, basicInfo } = useContext(DesktopContext);

  const { company_name } = profileInfo || {};
  const { review_link_more, default_review_link } = basicInfo || {};
  const [reviewLink, setReviewLink] = useState("");
  const defaultMessageConst = getReviewRequestBodyText(
    company_name,
    reviewLink
  );
  const defaultMessage = defaultMessageConst;
  const [displayedMessage, setDisplayedMessage] = useState(defaultMessage);
  // to do verify similar use effects
  useEffect(() => {
    const newReviewLink = getReviewLink(profileInfo, basicInfo);
    setReviewLink(newReviewLink);
  }, [default_review_link, review_link_more, basicInfo]);

  useEffect(() => {
    setDisplayedMessage(getReviewRequestBodyText(company_name, reviewLink));
  }, [company_name, reviewLink]);

  function handleOnChange(e: any) {
    setDisplayedMessage(e["request_review_message"]);
  }

  return (
    <div>
      <h2>Message</h2>

      {
        <div className="container flex justify-between">
          <DynamicInput
            name="request_review_message"
            value={displayedMessage}
            handleLocalEdits={handleOnChange}
            customStyle="mb-3"
            textarea={true}
            edit={true}
          />
        </div>
      }
    </div>
  );
}
