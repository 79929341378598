import React, { useContext, useEffect, useState } from "react";
import { RequestAReviewModalContext } from "../RequestAReviewModal/RequestAReviewModal";
import PrimaryLink from "../../../uiwrappers/PrimaryLink/PrimaryLink";
import { DesktopContext } from "../../../../contexts/DesktopContextProvider/DesktopContextProvider";
import SecondaryButton from "../../../uiwrappers/SecondaryButton/SecondaryButton";
import PrimaryButton from "../../../uiwrappers/PrimaryButton/PrimaryButton";
import copy from "copy-to-clipboard";
import { toast } from "react-toastify";
import { getReviewLink } from "../RequestAReviewModal/utils";

const RequestAReviewModalCustomButtons = () => {
  const [reviewLink, setReviewLink] = useState<string>("");
  const {
    profileInfo,
    basicInfo,
    downloadAppOnIphoneWithNoHomeButton,
    isFacebookAuthenticated,
  } = useContext(DesktopContext);
  const {
    recipients,
    isSecondSubmitProcessing,
    onSecondSubmitHandler,
    isSubmitProcessing,
    onSubmitHandler,
    currentSelection,
    isThirdSubmitProcessing,
    onThirdSubmitHandler,
    setReviewRequestModalOpen,
    setConnectToFBModalOpen,
    setCreatePostModalOpen,
    setSharingReviewLink,
  } = useContext(RequestAReviewModalContext);

  useEffect(() => {
    const review_link = getReviewLink(profileInfo, basicInfo);
    setReviewLink(review_link);
  }, [basicInfo]);
  const copyToClipboard = () => {
    copy(reviewLink);
    toast.success("Review link copied");
  };

  function shareLink() {
    if (isFacebookAuthenticated) {
      setCreatePostModalOpen(true);
    } else {
      setConnectToFBModalOpen(true);
    }
    setReviewRequestModalOpen(false);
    setSharingReviewLink && setSharingReviewLink(true);
  }

  return (
    <>
      {/* mobile */}
      <div
        className={`sm:hidden p-4 flex flex-col space-y-2 w-full justify-center items-center border-t ${
          downloadAppOnIphoneWithNoHomeButton ? "pb-8" : ""
        }`}
      >
        {recipients.length === 0 && (
          <div className="flex flex-row items-center space-x-3 my-2">
            <div className="whitespace-nowrap">Share Link</div>
            <button
              onClick={shareLink}
              className="flex flex-shrink-0 w-10 h-10 justify-center items-center border bg-white hover:bg-blue-50 rounded-full p-1"
            >
              <img className="w-6 h-6" src="/FBIconReview.svg" alt="" />
            </button>
            {recipients.length === 0 && (
              <div className="flex justify-start">
                <PrimaryLink
                  id={`copy-link`}
                  text="Copy Link"
                  size="md"
                  onClickFunc={() => {
                    copyToClipboard();
                  }}
                />
              </div>
            )}
          </div>
        )}

        <div
          className={`flex flex-col w-full ${
            downloadAppOnIphoneWithNoHomeButton ? "pb-8" : ""
          }`}
        >
          {currentSelection !== "send" && (
            <div
              className={`flex ${
                recipients.length === 0
                  ? "justify-end"
                  : "justify-between w-full"
              }`}
            >
              <SecondaryButton
                id="send-an-email-request-a-review"
                data-cy="full-screen-modal-second-submit-button"
                text="Email"
                size="md"
                loading={isSecondSubmitProcessing}
                disabled={isSecondSubmitProcessing}
                onClickFunc={onSecondSubmitHandler}
                customStyle="w-full mr-4"
              />
              <PrimaryButton
                id="send-a-text-request-a-review"
                text="Text"
                size="md"
                disabled={isSubmitProcessing}
                loading={isSubmitProcessing}
                onClickFunc={onSubmitHandler}
                customStyle="w-full"
              />
            </div>
          )}

          {currentSelection === "send" && (
            <div className="w-full px-4">
              <PrimaryButton
                id={currentSelection === "send" ? "send-request-a-review" : ""}
                text="Send"
                size="md"
                disabled={isThirdSubmitProcessing}
                loading={isThirdSubmitProcessing}
                onClickFunc={onThirdSubmitHandler}
                customStyle="w-full"
              />
            </div>
          )}
        </div>
      </div>

      {/* desktop */}
      <div className="w-full pt-4 hidden sm:block">
        <div className="sm:flex sm:flex-row items-center justify-between">
          {recipients.length === 0 && (
            <div className="flex flex-row items-center space-x-3 my-2">
              <div className="whitespace-nowrap">Share Link</div>
              <button
                onClick={() => {
                  setReviewRequestModalOpen(false);
                  isFacebookAuthenticated
                    ? setCreatePostModalOpen(true)
                    : setConnectToFBModalOpen(true);
                }}
                className="flex flex-shrink-0 w-10 h-10 justify-center items-center border bg-white hover:bg-blue-50 rounded-full p-1"
              >
                <img className="w-6 h-6" src="/FBIconReview.svg" alt="" />
              </button>

              <PrimaryLink
                id={`copy-link`}
                text="Copy Link"
                size="md"
                onClickFunc={() => {
                  copyToClipboard();
                }}
                customStyle="whitespace-nowrap"
              />
            </div>
          )}

          <div className="hidden sm:flex sm:w-full items-center justify-end">
            <div className="sm:justify-end align-middle items-center">
              <div className="flex">
                {currentSelection === "send" ? (
                  <PrimaryButton
                    id={
                      currentSelection === "send" ? "send-request-a-review" : ""
                    }
                    data-cy="full-screen-modal-third-submit-button"
                    text="Send"
                    size="md"
                    loading={isThirdSubmitProcessing}
                    disabled={isThirdSubmitProcessing}
                    onClickFunc={onThirdSubmitHandler}
                  />
                ) : (
                  <>
                    <SecondaryButton
                      id="send-an-email-request-a-review"
                      data-cy="full-screen-modal-second-submit-button"
                      text="Email"
                      size="md"
                      loading={isSecondSubmitProcessing}
                      disabled={isSecondSubmitProcessing}
                      onClickFunc={onSecondSubmitHandler}
                      customStyle="mr-2"
                    />
                    <PrimaryButton
                      id="send-a-text-request-a-review"
                      data-cy="full-screen-modal-submit-button"
                      text="Text"
                      size="md"
                      loading={isSubmitProcessing}
                      disabled={isSubmitProcessing}
                      onClickFunc={onSubmitHandler}
                    />
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RequestAReviewModalCustomButtons;
