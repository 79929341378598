import React from "react";
import { checkIfAlreadySentRequestReviewWithin6Months } from "../../contacts/ContactsPage/utils";
import { CheckCircleIcon } from "@heroicons/react/solid";
import StackedList from "../../../common/StackedList/StackedList";
import { IContact } from "../../contacts/Contact/types";
import PrimaryLink from "../../../uiwrappers/PrimaryLink/PrimaryLink";

interface ReviewContactTableProps {
  displayedContacts: IContact[];
  contactOnClickHandler: (_contact: IContact | string) => void;
  newContact: string;
  searchedContact: string;
  handleAddAllRecipients: () => void;
}

export default function ReviewContactTable({
  displayedContacts,
  contactOnClickHandler,
  newContact,
  searchedContact,
  handleAddAllRecipients,
}: ReviewContactTableProps): React.ReactElement {
  function getTipText(contact: IContact) {
    const bool = checkIfAlreadySentRequestReviewWithin6Months(
      new Date(contact?.date_request_review_sent)
    );
    if (bool) {
      return (
        <div className="flex gap-1 items-center text-gray-700 h-full">
          <CheckCircleIcon className="w-4 h-4 text-green-600" />
          <div>Sent a request in the last 6 months</div>
        </div>
      );
    }
  }

  return (
    <div className="relative flex flex-col justify-between w-full sm:h-300  ">
      <div className="flex flex-row justify-between w-full py-2">
        <PrimaryLink
          text="Add all"
          size="md"
          onClickFunc={handleAddAllRecipients}
        />
        <div className="border-t-0  text-sm-medium text-gray-500">
          {displayedContacts.length} contacts
        </div>
      </div>
      <div className="flex flex-col overflow-auto border-t">
        <StackedList
          data={displayedContacts}
          renderItemAdditionalInfo={getTipText}
          onItemClickHandler={contactOnClickHandler}
          itemStyle="px-6 sm:px-2"
        />

        {displayedContacts.length === 0 &&
          newContact !== "" &&
          searchedContact !== "" && (
            <div
              className="flex hover:bg-blue-50 px-8 py-4 cursor-pointer border-b border-gray-300"
              onClick={() => contactOnClickHandler(newContact)}
            >
              <div className="flex flex-col justify-center w-full">
                <div className="text-sm-normal flex justify-start">
                  Request review from
                </div>
                <div className="text-sm-medium flex justify-start">
                  {newContact}
                </div>
              </div>
            </div>
          )}
      </div>
    </div>
  );
}
