import { IContact } from "../Contact/types";

export const BLANK_CONTACT: IContact = {
  profile_photo: "",
  first_name: "",
  last_name: "",
  phone_number: "",
  email: "",
  address: "",
  notes: "",
  zipcode: "",
  city: "",
  state: "",
  country: "",
  stripe_customer_id: "",
  date_request_review_sent: "",
  customer_request_review: "",
  value: "",
};
