import React, { useContext, useEffect, useState } from "react";
import GenericBadge from "../../../uiwrappers/GenericBadge/GenericBadge";
import { getRecipientStyle } from "./utils";
import { RequestAReviewModalContext } from "../RequestAReviewModal/RequestAReviewModal";

export default function Recipient() {
  const {
    recipients,
    setRecipients,
    currentSelection,
    setAlert,
    editRecipientModalOpen,
    setEditRecipientModalOpen,
    setEditedRecipient,
    areValidRecipients,
    setAreValidRecipients,
    setDisplayedContactsInModal,
    displayedContactsInModal,
    setReviewRequestModalOpen,
    setStage,
    counter,
  } = useContext(RequestAReviewModalContext);
  const [missingFirstName, setMissingFirstName] = useState(false);

  useEffect(() => {
    if (recipients.length !== 0) {
      // const isMissingFirstName = recipients.some((ele: any) => !ele.first_name);
      // isMissingFirstName
      //   ? setMissingFirstName(true)
      //   : setMissingFirstName(false);
      if (currentSelection === "text") {
        const bool = recipients.some((ele: any) => !ele.phone_number);
        if (bool) setAreValidRecipients(false);
        else setAreValidRecipients(true);
      } else if (currentSelection === "email") {
        const bool = recipients.some((ele: any) => !ele.email);
        if (bool) {
          setAreValidRecipients(false);
        } else setAreValidRecipients(true);
      } else {
        setAreValidRecipients(true);
        setMissingFirstName(false);
      }
    } else {
      setAreValidRecipients(true);
      setMissingFirstName(false);
    }
  }, [recipients, editRecipientModalOpen, currentSelection]);

  useEffect(() => {
    if (!areValidRecipients) {
      if (currentSelection === "text") {
        if (missingFirstName) {
          setAlert(
            "All recipients must have a phone number and a first name. Please click to edit or remove recipient."
          );
        } else {
          setAlert(
            "All recipients must have a phone number. Please click to edit or remove recipient."
          );
        }
      } else if (currentSelection === "email") {
        if (missingFirstName) {
          setAlert(
            "All recipients must have an email and a first name. Please click to edit or remove recipient."
          );
        } else {
          setAlert(
            "All recipients must have an email. Please click to edit or remove recipient."
          );
        }
      }
    } else if (missingFirstName) {
      setAlert(
        "All recipients must have a first name. Please click to edit or remove recipient."
      );
    } else {
      if (!counter) setAlert("");
    }
  }, [areValidRecipients, missingFirstName, recipients, currentSelection]);

  function deleteRecipientHandler(recipient: any) {
    let newRecipients = [];
    // if recipient is an existing contact, filter out by id. If not, filter out by value.
    newRecipients = recipients.filter((item: any) =>
      !item.hasOwnProperty("id")
        ? item.value !== recipient.value
        : item.id !== recipient.id
    );
    if (recipient.hasOwnProperty("id")) {
      const newDisplayedContacts = displayedContactsInModal.concat(recipient);
      newDisplayedContacts.sort(function (a, b) {
        const lastA = a?.last_name?.toUpperCase();
        const lastB = b?.last_name?.toUpperCase();
        if (lastA < lastB) {
          return -1;
        }
        if (lastA > lastB) {
          return 1;
        }
        return 0;
      });
      setDisplayedContactsInModal(newDisplayedContacts);
    }
    setRecipients(newRecipients);

    if (newRecipients.length === 0) {
      setStage("1");
    }
  }

  function recipientOnClickHandler(e, recipient) {
    setReviewRequestModalOpen(false);
    setEditRecipientModalOpen(true);
    setEditedRecipient(recipient);
  }

  function getDisplayRecipient(recipient: any) {
    // if there is no contact id, display recipient value which can be a phone number or email
    if (!recipient.hasOwnProperty("id")) {
      return recipient.value;
    }
    // if there is name, display name
    if (!!recipient.first_name || !!recipient.last_name) {
      return recipient.first_name + " " + recipient.last_name;
    }
    // if recipient is already added to contact list but have no name, display phone number or email
    else if (!recipient.first_name && !recipient.last_name) {
      return recipient?.phone || recipient?.phone_number || recipient.email;
    }
  }

  return (
    <>
      {recipients.map((recipient: any) => (
        <div
          key={typeof recipient === "string" ? recipient : recipient.id}
          id="recipient"
          className={`flex space-x-1 justify-center items-center rounded-full text-sm-medium mr-1.5 my-1 ${
            getRecipientStyle(recipient, currentSelection).textStyle
          }`}
        >
          <GenericBadge
            text={getDisplayRecipient(recipient)}
            color="blue"
            textSize="sm"
            showXIcon={true}
            textOnClickFunc={(e) => recipientOnClickHandler(e, recipient)}
            XIconOnClickFunc={() => deleteRecipientHandler(recipient)}
          />
        </div>
      ))}
    </>
  );
}
