import React, { useCallback, useContext } from "react";
import useIsMobile from "../../../hooks/useIsMobile";
import { RequestAReviewModalContext } from "../../primaryrender/reviews/RequestAReviewModal/RequestAReviewModal";
import { renderToString } from "react-dom/server";
import reactStringReplace from "react-string-replace";
import { cleanHTML } from "./utils";

interface CustomChipArea {
  CustomChipTextArea: { [key: string]: string };
}

const CustomChip: React.FC<CustomChipArea> = ({ CustomChipTextArea }) => {
  const isMobile = useIsMobile();
  const {
    alert,
    showAll,
    localRequestReviewMessage,
    handleLocalMessageOnChange,
  } = useContext(RequestAReviewModalContext);

  const varDiv = useCallback((content: string) => {
    return (
      <div
        className={`inline-flex items-center rounded-full bg-blue-100 text-blue-800 py-0.5 pl-2 pr-2 text-sm-medium mb-1 `}
        contentEditable={false}
      >
        {content}
      </div>
    );
  }, []);

  const messageToHTML = useCallback(
    (currentValue: string): string => {
      let updatedString = currentValue;

      updatedString = reactStringReplace(updatedString, "\n", (match, i) => (
        <br key={i} />
      ));

      Object.keys(CustomChipTextArea).forEach((key) => {
        updatedString = reactStringReplace(updatedString, key, (match, i) =>
          varDiv(CustomChipTextArea[key])
        );
      });

      return renderToString(updatedString);
    },
    [CustomChipTextArea, varDiv]
  );

  const htmlToMessage = useCallback(
    (currentValue: string): string => {
      currentValue = currentValue.replaceAll("\n", "");
      currentValue = currentValue.replace(
        /<br\s+data-reactroot=""\s*\/?>/gi,
        "\n"
      );
      currentValue = cleanHTML(currentValue);

      Object.keys(CustomChipTextArea).forEach((key) => {
        currentValue = currentValue.replaceAll(CustomChipTextArea[key], key);
      });

      return currentValue;
    },
    [CustomChipTextArea]
  );

  return (
    <>
      <div
        data-testid="inputContainer"
        className="flex justify-center w-full h-full box-content mb-2"
      >
        {isMobile ? (
          <div
            className="bg-white h-full border border-gray-300 rounded-lg w-full text-body-normal text-gray-900 focus:ring-0 resize-none textarea-min-height p-4 pt-2 overflow-auto"
            contentEditable={true}
            dangerouslySetInnerHTML={{
              __html: messageToHTML(localRequestReviewMessage),
            }}
            onBlur={(e) => {
              handleLocalMessageOnChange({
                target: {
                  value: htmlToMessage(e.currentTarget.innerHTML),
                },
              });
            }}
          ></div>
        ) : (
          <div className={`w-full h-full mb-2 ${!showAll && !alert && "mt-4"}`}>
            <div
              className="bg-white h-full border border-gray-300 rounded-lg w-full text-body-normal text-gray-900 focus:ring-0 resize-none textarea-min-height p-4 pt-2 overflow-auto"
              contentEditable={true}
              style={{
                minHeight: "19.5em",
              }}
              dangerouslySetInnerHTML={{
                __html: messageToHTML(localRequestReviewMessage),
              }}
              onBlur={(e) => {
                handleLocalMessageOnChange({
                  target: {
                    value: htmlToMessage(e.currentTarget.innerHTML),
                  },
                });
              }}
            ></div>
          </div>
        )}
      </div>
    </>
  );
};

export default CustomChip;
