const cleanNode = (node: ChildNode): string => {
  if (node.nodeType === Node.TEXT_NODE) {
    return node.nodeValue || "";
  }
  if (node.nodeType === Node.ELEMENT_NODE) {
    const tag = (node as HTMLElement).tagName.toLowerCase();
    if (tag === "br") {
      return "\n";
    }
    if (tag === "span") {
      return (node as HTMLElement).textContent || "";
    }
    return node.childNodes.length
      ? Array.from(node.childNodes).map(cleanNode).join("")
      : "";
  }
  return "";
};

export const cleanHTML = (html: string): string => {
  const doc = new DOMParser().parseFromString(html, "text/html");
  return cleanNode(doc.body);
};
