import { IContact } from "../../contacts/Contact/types";

export enum ContactsVisibility {
  SHOW_ALL = "Show all",
  HIDE = "Hide",
  NONE = "",
}

export interface ChannelConfig {
  validator: (ele: IContact) => boolean;
  alertMessage: string;
}

export type ChannelType = "text" | "email" | "send";
