import { checkIfAlreadySentRequestReviewWithin6Months } from "../RequestAReviewModal/utils";

export function getRecipientStyle(recipient: any, currentSelection: string) {
  const style = { textStyle: "", removeButtonStyle: "" };

  if (
    checkIfAlreadySentRequestReviewWithin6Months(
      new Date(recipient.date_request_review_sent)
    )
  ) {
    style.textStyle = "bg-red-100 text-red-800";
    style.removeButtonStyle =
      "text-red-800 hover:bg-red-200 hover:text-red-500 focus:bg-red-500";
  } else {
    if (currentSelection === "text") {
      if (recipient.phone_number === "") {
        style.textStyle = "bg-red-100 text-red-800";
        style.removeButtonStyle =
          "text-red-800 hover:bg-red-200 hover:text-red-500 focus:bg-red-500";
      } else {
        style.textStyle = "bg-blue-100 text-blue-800";
        style.removeButtonStyle =
          "text-indigo-400 hover:bg-indigo-200 hover:text-indigo-500 focus:bg-indigo-500";
      }
    } else if (currentSelection === "email") {
      if (recipient.email === "") {
        style.textStyle = "bg-red-100 text-red-800";
        style.removeButtonStyle =
          "text-red-800 hover:bg-red-200 hover:text-red-500 focus:bg-red-500";
      } else {
        style.textStyle = "bg-blue-100 text-blue-800";
        style.removeButtonStyle =
          "text-indigo-400 hover:bg-indigo-200 hover:text-indigo-500 focus:bg-indigo-500";
      }
    } else {
      style.textStyle = "bg-blue-100 text-blue-800";
      style.removeButtonStyle =
        "text-indigo-400 hover:bg-indigo-200 hover:text-indigo-500 focus:bg-indigo-500";
    }
  }
  return style;
}
