import React from "react";
import { AlertDisplayProps } from "./types";
import Alert from "../../../uiwrappers/Alert/Alert";

const AlertDisplay: React.FC<AlertDisplayProps> = ({
  alertMessage,
  subAlert,
}) => {
  if (!alertMessage) {
    return null;
  }
  return (
    <div className="flex w-full justify-between mt-1 mb-4">
      <div>
        <Alert alertMessage={alertMessage} />
        {subAlert && (
          <div className="text-sm-normal text-gray-500">{subAlert}</div>
        )}
      </div>
    </div>
  );
};

export default AlertDisplay;
