import React from "react";
import Recipient from "../Recipient/Recipient";
import { RecipientListProps } from "./types";
import useIsMobile from "../../../../hooks/useIsMobile";

const RecipientList: React.FC<RecipientListProps> = ({
  recipients,
  contactsVisibility,
}) => {
  if (recipients.length === 0) {
    return null;
  }
  const isMobile = useIsMobile();
  return (
    <div
      className={`relative rounded-md w-full ${
        contactsVisibility === "Hide"
          ? "sm:overflow-y-auto sm:max-h-400"
          : "Show all" && "sm:max-h-75 sm:overflow-hidden"
      } ${isMobile ? "max-h-75 overflow-y-auto" : ""}`}
    >
      <div id="recipients" className="flex flex-wrap items-center pt-2">
        <Recipient />
      </div>
    </div>
  );
};

export default RecipientList;
